import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Switcher, Switch } from "@nulogy/components";
import { config } from "../../playgrounds/switcher";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Switcher`}</h1>
    <p>{`A switcher allows the user to toggle between 2 or more options.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Use when`}</h2>
    <ul>
      <li parentName="ul">{`Organizing, grouping, or filtering data and content.`}</li>
      <li parentName="ul">{`Needing to toggle between different ways to organize data. Such as visualizing data in a grid vs table format, group data by different facets, or to immediately filter content displayed in a section of a page.`}</li>
      <li parentName="ul">{`Giving the user a way to immediately manipulate the UI without the need of a submit button.`}</li>
    </ul>
    <h2>{`Don't use when`}</h2>
    <ul>
      <li parentName="ul">{`Navigating between different sets of data or distinct sets of content that live separately. Tabs should be used instead.`}</li>
      <li parentName="ul">{`The use case calls for more than one option being selected at a time. Checkboxes should be used instead.`}</li>
      <li parentName="ul">{`Used as a form input. Radio buttons should be used instead.`}</li>
      <li parentName="ul">{`Used as an on/off selection component. Toggles should be used instead.`}</li>
      <li parentName="ul">{`The use case calls for more than 4 options, a dropdown should be used instead.`}</li>
    </ul>
    <h2>{`Interaction guidelines`}</h2>
    <p>{`When a user uses a switcher, ideally, only the content being switched should be reloaded or reorganized. To prevent loss of context, the switcher and any navigation components should remain visible.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Considering the intended use of the switcher — a low-risk control component for organizing content in different ways — it can be used without a label when the set of options are descriptive enough by themselves. In this case, a hidden label should be used.`}</p>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      